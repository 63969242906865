import React from "react";
import Select from "react-select";
const EmployeeSelector = ({ employees, selectedEmployees, onSelectChange, isDisabled }) => {
  return (
    <div className="mb-4">
      <label className="block text-gray-700 text-sm font-bold mb-2">Select Employees</label>
      <Select
        options={employees.map((employee) => ({ value: employee.id, label: employee.name }))}
        isMulti
        value={selectedEmployees}
        onChange={onSelectChange}
        isDisabled={isDisabled}
      />
    </div>
  );
};
export default EmployeeSelector;
