import React from "react";

const StarEmpty = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.0"
      id="Layer_1"
      viewBox="0 0 64 64"
      fill="#000000">
      <g id="SVGRepo_bgCarrier"></g>
      <g id="SVGRepo_tracerCarrier"></g>
      <g id="SVGRepo_iconCarrier">
        {" "}
        <path
          fill="#ff6633"
          d="M32.001,2.484c0.279,0,0.463,0.509,0.463,0.509l8.806,18.759l20.729,3.167L47,40.299L50.541,62 l-18.54-10.254L13.461,62l3.541-21.701L2.003,24.919l20.729-3.167L31.53,3.009C31.53,3.009,31.722,2.484,32.001,2.484 M32.001,0.007 c-0.775,0-1.48,0.448-1.811,1.15l-8.815,18.778L1.701,22.941c-0.741,0.113-1.356,0.632-1.595,1.343 c-0.238,0.71-0.059,1.494,0.465,2.031l14.294,14.657l-3.378,20.704c-0.124,0.756,0.195,1.517,0.822,1.957 C12.653,63.877,13.057,64,13.461,64c0.332,0,0.666-0.084,0.968-0.25l17.572-9.719l17.572,9.719c0.302,0.166,0.636,0.25,0.968,0.25 c0.404,0,0.808-0.123,1.151-0.366c0.627-0.44,0.946-1.201,0.822-1.957l-3.378-20.704l14.294-14.657 c0.523-0.537,0.703-1.321,0.465-2.031c-0.238-0.711-0.854-1.229-1.595-1.343l-19.674-3.006L33.812,1.157 C33.481,0.455,32.776,0.007,32.001,0.007L32.001,0.007z"></path>{" "}
      </g>
    </svg>
    // <svg xmlns="http://www.w3.org/2000/svg" version="1.0" viewBox="0 0 64 64"><g/><g/><g fill="#23A5F7">{&quot; &quot;}<path d="M32.001 2.484c.279 0 .463.509.463.509l8.806 18.759 20.729 3.167L47 40.299 50.541 62l-18.54-10.254L13.461 62l3.541-21.701-14.999-15.38 20.729-3.167L31.53 3.009s.192-.525.471-.525m0-2.477c-.775 0-1.48.448-1.811 1.15l-8.815 18.778-19.674 3.006a2.003 2.003 0 0 0-1.13 3.374l14.294 14.657-3.378 20.704a1.996 1.996 0 0 0 .822 1.957 1.99 1.99 0 0 0 2.12.117l17.572-9.719 17.572 9.719a1.991 1.991 0 0 0 2.119-.116c.627-.44.946-1.201.822-1.957l-3.378-20.704L63.43 26.316a2 2 0 0 0-1.13-3.374l-19.674-3.006-8.814-18.779a2.003 2.003 0 0 0-1.811-1.15z"/>{&quot; &quot;}</g></svg>
  );
};

export default StarEmpty;

