import { useState, useEffect } from 'react';

export function useShakeAnimation() {
  const [shake, setShake] = useState(false);

  useEffect(() => {
    if (shake) {
      const timer = setTimeout(() => {
        setShake(false);
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [shake]);

  return [shake, setShake];
}
