import React, { useEffect } from "react";
import axios from "axios";
import Snack from "./Snack.jsx";
import { useStore } from "../store";
import { useParams } from "react-router-dom";

const CACHE_KEY = "snacks";

const cacheKeyWithLocation = (location) => CACHE_KEY + "." + location;

const saveSnacksToCache = (snacks, location) => {
  const expirationDate = new Date();
  expirationDate.setDate(expirationDate.getDate() + 1);
  expirationDate.setHours(6, 0, 0, 0);
  const cacheData = {
    data: snacks,
    expiration: expirationDate.getTime(),
  };
  localStorage.setItem(cacheKeyWithLocation(location), JSON.stringify(cacheData));
};

const loadSnacksFromCache = (location) => {
  const cachedData = localStorage.getItem(cacheKeyWithLocation(location));
  if (cachedData) {
    const cacheData = JSON.parse(cachedData);
    const currentTime = new Date().getTime();
    if (currentTime < cacheData.expiration) {
      return cacheData.data;
    } else {
      localStorage.removeItem(cacheKeyWithLocation(location));
    }
  }
  return undefined;
};

function SnackList() {
  const { location } = useParams();

  const snacks = useStore((state) => state.snacks);
  const setSnacks = useStore((state) => state.setSnacks);
  const employee = useStore((state) => state.employee);
  const selectedCategory = useStore((state) => state.selectedCategory);

  useEffect(() => {
    async function fetchSnacks() {
      const cachedSnacks = loadSnacksFromCache(location);
      if (cachedSnacks !== undefined) {
        setSnacks(cachedSnacks);
        return;
      }
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/snacks`, {
        params: {
          userId: employee._id,
          location,
        },
      });
      if (response.data.success) {
        const fetchedSnacks = response.data.snacks;
        setSnacks(fetchedSnacks);
        saveSnacksToCache(fetchedSnacks, location);
      }
    }

    fetchSnacks();
  }, []);

  return (
    <div className="snack-list mb-50 mr-1 ml-1 md:mr-5 md:ml-5 grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
      {snacks
        .filter((snack) => {
          if (selectedCategory === "Favorite Snacks") {
            return snack.Purchasable && (employee["Favorite Snacks"]?.includes(snack._id) || false);
          } else {
            return snack.Purchasable && (selectedCategory.length === 0 || selectedCategory.includes(snack.Category));
          }
        })
        .map((snack) => (
          <Snack snack={snack} key={snack._id} />
        ))}
    </div>
  );
}

export default SnackList;
