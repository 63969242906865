import { useStore } from "../store";
import { useEffect } from "react";

export function useEmployeeGreetingController() {
  const setMonthlyExpenses = useStore((state) => state.setMonthlyExpenses);
  const employee = useStore((state) => state.employee);

  useEffect(() => {
    async function fetchMonthlyExpenses(employeeId) {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/monthly-expenses`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ employeeId }),
        });

        const data = await response.json();
        const totalExpenses = data.totalExpenses;

        setMonthlyExpenses(totalExpenses);

      } catch (error) {
        console.error("Error fetching monthly expenses:", error);
      }
    }

    if (employee && employee._id) {
      fetchMonthlyExpenses(employee._id);
    }
  }, [employee, setMonthlyExpenses]);
}
