import { useState, useEffect } from "react";
import { useStore } from "../store";
import { useNavigate, useParams } from "react-router-dom";
import { PURCHASE_SUCCESS_MESSAGE, PURCHASE_PROCESSING_MESSAGE, PURCHASE_ERROR_MESSAGE } from "../utils/constants";

export function useCartController() {
  const { location } = useParams();

  const [purchaseMessage, setPurchaseMessage] = useState("");
  const [purchaseSuccess, setPurchaseSuccess] = useState(false);
  const employee = useStore((state) => state.employee);
  const cartItems = useStore((state) => state.cartItems);
  const setCartItems = useStore((state) => state.setCartItems);
  const handleLogout = useStore((state) => state.handleLogout);
  const navigate = useNavigate();

  const handleAddItem = (item) => {
    const existingItem = cartItems.find((cartItem) => cartItem.id === item.id);
    if (existingItem) {
      const updatedCartItems = cartItems.map((cartItem) => {
        if (cartItem.id === item.id) {
          return { ...cartItem, quantity: cartItem.quantity + 1 };
        } else {
          return cartItem;
        }
      });
      setCartItems(updatedCartItems);
    } else {
      setCartItems([...cartItems, { ...item, quantity: 1 }]);
    }
  };
  const handleRemoveItem = (item) => {
    const existingItem = cartItems.find((cartItem) => cartItem.id === item.id);
    if (existingItem && existingItem.quantity > 1) {
      const updatedCartItems = cartItems.map((cartItem) => {
        if (cartItem.id === item.id) {
          return { ...cartItem, quantity: cartItem.quantity - 1 };
        } else {
          return cartItem;
        }
      });
      setCartItems(updatedCartItems);
    } else {
      const updatedCartItems = cartItems.filter((cartItem) => cartItem.id !== item.id);
      setCartItems(updatedCartItems);
    }
  };

  const handleBuy = async () => {
    setPurchaseMessage(PURCHASE_PROCESSING_MESSAGE);
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/purchase`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          items: cartItems,
          employeeId: employee["_id"],
        }),
      });

      const data = await response.json();
      console.log(data);
      setPurchaseMessage(PURCHASE_SUCCESS_MESSAGE);
      setPurchaseSuccess(true);
    } catch (error) {
      setPurchaseMessage(PURCHASE_ERROR_MESSAGE);
      console.error(error);
    }
  };

  useEffect(() => {
    if (purchaseSuccess) {
      const timer = setTimeout(() => {
        setPurchaseSuccess(false);
        setPurchaseMessage("");
        handleLogout();
        navigate("/login/" + location);
      }, 1500);
      return () => clearTimeout(timer);
    }
  }, [purchaseSuccess]);

  return {
    handleAddItem,
    handleRemoveItem,
    handleBuy,
    purchaseMessage,
  };
}
