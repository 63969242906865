import React from "react";
import { useCartController } from "../controllers/CartController";
import { useStore } from "../store";
import { PURCHASE_SUCCESS_MESSAGE } from "../utils/constants";
import CartTotal from "./CartTotal";

function Cart() {
  const cartItems = useStore((state) => state.cartItems);
  const setCartItems = useStore((state) => state.setCartItems);
  const { handleAddItem, handleRemoveItem, handleBuy, purchaseMessage } = useCartController(
    cartItems,
    setCartItems
  );
  const total = cartItems.reduce((acc, item) => acc + item.price * item.quantity, 0);

  return (
    <div className="cart flex flex-col h-full w-full items-center justify-between text-xs md:text-sm lg:text-base xl:text-lg 2xl:text-xl adaptavist-grey-text">
      {
        <div className="h-full w-full overflow-y-scroll mb-30 mt-14 items-center justify-center">
          {cartItems.map((item) => (
            <div className="h-14 flex flex-row justify-between items-center mr-2 ml-2" key={item["_id"]}>
              <div className="basis-4/12 text-xs md:text-sm lg:text-sm xl:text-base 2xl:text:xl truncate">
                <span className="overflow-hidden">{item.name}</span>
              </div>
              <div className="basis-4/12 flex flex-row justify-between items-center">
                <button
                  className="base-1/4 justify-self-start hover:adaptavist-orange-darker px-1 py-0.5 sm:px-2 sm:py-1 md:px-3 md:py-1.5 rounded adaptavist-orange"
                  onClick={() => handleRemoveItem(item)}>
                  -
                </button>
                <span className="base-1/4 justify-self-center mx-2.5 text-sm md:text-base lg:text-lg align-middle">
                  {item.quantity}
                </span>
                <button
                  className="base-1/4 justify-self-end  hover:adaptavist-orange-darker px-1 py-0.5 sm:px-2 sm:py-1 md:px-3 md:py-1.5 rounded adaptavist-orange"
                  onClick={() => handleAddItem(item)}>
                  +
                </button>
              </div>
              <div className="basis-2/12 text-right text-xs md:text-sm lg:text-sm xl:text-base 2xl:text:xl whitespace-nowrap">
                {(item.price * item.quantity).toFixed(2)} €
              </div>
            </div>
          ))}
        </div>
      }

      {purchaseMessage && (
        <div
          className={`purchase-message text-center ${
            purchaseMessage === PURCHASE_SUCCESS_MESSAGE ? "text-green-500" : "text-yellow-500"
          }`}>
          {purchaseMessage}
        </div>
      )}

      <div className="checkout pb-10 pt-2 sticky bottom-0 w-full adaptavist-black">
        <div className="flex flex-col items-center justify-center">
          <div className="adaptavist-grey-text text-xs sm:text-sm md:text-base lg:text-2xl xl:text-3xl 2xl:text-3xl mr-4 whitespace-nowrap">
            Total: {total.toFixed(2)} €
          </div>
          <button
            className="adaptavist-orange hover:adaptavist-orange-darker font-bold rounded-full py-2 px-10 sm:py-2 sm:px-10 md:py-2 md:px-12 lg:py-3 lg:px-16 xl:py-4 xl:px-20"
            onClick={handleBuy}>
            CHECKOUT
          </button>
        </div>
      </div>
      {/* <CartTotal total={total} handleBuy={handleBuy}></CartTotal> */}
    </div>
  );
}

export default Cart;
