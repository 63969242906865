export const handleLogin = async (pin) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ pin: pin }),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    return {
      success: false,
      error: "An error occurred. Please try again later.",
    };
  }
};
