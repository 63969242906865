import React, { useEffect } from "react";
import { useStore } from "../store";

function Profile() {
  const setEmployeePurchasesEvaluation = useStore((state) => state.setEmployeePurchasesEvaluation);
  const employee = useStore((state) => state.employee);
  const monthlyExpenses = useStore((state) => state.monthlyExpenses);
  const purchases = useStore((state) => state.purchases);
  const setPurchases = useStore((state) => state.setPurchases);

  useEffect(() => {
    async function fetchPurchases() {
      if (!purchases) {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/employee-purchases`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ employeeId: employee._id }),
        });

        if (response.ok) {
          const data = await response.json();
          setPurchases(data.purchases);
        } else {
          console.log("Error fetching purchases.");
        }
      }
    }

    async function fetchEmployeePurchasesEvaluation() {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/employee-purchases-evaluation`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ employeeId: employee._id }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        setEmployeePurchasesEvaluation(data);
      } else {
        console.log("Error fetching employee purchases evaluation.");
      }
    }

    if (employee) {
      fetchPurchases();
      fetchEmployeePurchasesEvaluation();
    }
  }, [employee]);

  return (
    <div className="grid grid-cols-1 gap-6 mb-6">
      <div className="flex flex-col items-center justify-center border-r md:border-none border-adaptavist-orange">
        <img src="/images/smiley.jpg" alt="employee" className="w-20 h-20 rounded-full mb-4" />
        <h2 className="text-2xl font-bold mb-2">{employee["Full name"]}</h2>
        <p className="text-lg text-gray-600">{employee["E-Mail"]}</p>
        <p className="text-lg text-gray-600">{employee["Office"]}</p>
        <div className="flex justify-center w-full mt-4">
          <div className="flex justify-between w-2/3">
            <div>
              <h3 className="font-medium text-lg">Month's Expenses</h3>
              <p className="text-2xl font-bold">{monthlyExpenses.toFixed(2)}€</p>
            </div>
            <div>
              <h3 className="font-medium text-lg">Total Spent</h3>
              <p className="text-2xl font-bold">
                {purchases
                  ? purchases.reduce((total, purchase) => total + purchase.price, 0).toFixed(2)
                  : 0}
                €
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;

