import React, { useState } from "react";
import { useAdminStore } from "../../admin-store";
export const AdminLogin = () => {
  const setIsAdmin = useAdminStore((state) => state.setIsAdmin);

  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/validate-admin-access`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Snackbuddy-Admin": password,
        },
      });
      if (response.status === 200) {
        setIsAdmin();
        setError("");
      } else {
        setError("Incorrect password. Please try again.");
      }
    } catch (error) {
      console.log("ERROR", error);
      setError("An error occurred. Please try again later.");
    }
  };
  return (
    <div className="flex items-center justify-center h-screen h-full flex flex-col bg-gradient-to-r from-gray-100 to-veniture-lighter60">
      <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4" onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
            Admin
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="password"
            type="password"
            placeholder="Enter password"
            value={password}
            autoFocus
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        {error && <p className="text-red-500 text-xs italic">{error}</p>}
        <div className="flex items-center justify-between">
          <button className="bg-blue-500 hover:bg-blue-700 adaptavist-grey-text font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

