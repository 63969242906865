import { useLoginController } from "../controllers/LoginController";
import NumberPad from "./NumberPad";
import PinInput from "./PinInput";
import config from "../config/config";

function Login() {
  const { pin, shake, loginMessage, handleNumberClick, handleDeleteClick, handleGuestLogin } = useLoginController();

  return (
    <div className="login-page-container flex flex-col sm:flex-row h-full">
      <div
        className="bg-center basis-3/12 sm:basis-4/12"
        style={{
          backgroundImage: `url(${config.snackImageUrl})`,
        }}
      ></div>
      <div className="login-form-container basis-9/12 sm:basis-12/12 flex flex-col items-center justify-center place-items-center bg-gradient-to-r adaptavist-black">
        <div className="logo-container absolute top-10 right-10">
          <div className="snackbuddy-text-logo-container flex items-center justify-center">
            <div className="snackbuddy-text">
              <div className="text-2xl sm:text-2xl md:text-3xl lg:text-3xl xl: 2xl:  font-bold text-adaptavist-base">SNACK</div>
              <div className="text-2xl sm:text-2xl md:text-3xl lg:text-3xl xl: 2xl:  font-bold adaptavist-grey-text">BUDDY</div>
            </div>
            <div className="adaptavist-logo">
              <img className="w-36 sm:w-36 md:w-40 lg:w-40 xl: 2xl:" src="/images/adaptavist.png" alt="Adaptavist" />
            </div>
          </div>
        </div>
        <div className="input-field-container my-4">
          <PinInput pin={pin} shake={shake} />
        </div>
        {loginMessage && <div className="login-message">{loginMessage}</div>}
        <div className="number-pad-container my-4">
          <NumberPad handleNumberClick={handleNumberClick} handleDeleteClick={handleDeleteClick} />
        </div>
        <div className="bottom-10 right-10 absolute justify-center">
          <button className="adaptavist-orange hover:adaptavist-orange-darker adaptavist-grey-text rounded-full font-bold h-20 w-20" onClick={handleGuestLogin}>
            GUEST
          </button>
        </div>
      </div>
    </div>
  );
}

export default Login;
