import React, { useState } from "react";
import { useStore } from "../store";

function Password() {
  const [newPin, setNewPin] = useState("");
  const [statusMessage, setStatusMessage] = useState({ message: "", color: "" });
  const employee = useStore((state) => state.employee);

  const handleGeneratePin = async () => {
    setStatusMessage({ message: "", color: "" });
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/generate-unique-password`);
      const data = await response.json();
      setNewPin(data.newPin);
    } catch (error) {
      console.error("Error generating new PIN:", error);
    }
  };

  const handleConfirmPin = async () => {
    if (!newPin) {
      setStatusMessage({ message: "Please generate a PIN first.", color: "text-yellow-500" });
      return;
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/change-pin`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ employeeId: employee._id, newPin }),
      });

      if (response.ok) {
        setStatusMessage({ message: "PIN successfully changed.", color: "text-green-500" });
      } else {
        console.log("Error changing PIN.");
        setStatusMessage({ message: "Error changing PIN.", color: "text-red-500" });
      }
    } catch (error) {
      console.error("Error confirming new PIN:", error);
      setStatusMessage({ message: "Error confirming new PIN.", color: "text-red-500" });
    }
  };

  return (
    <div className="space-y-6">
      <div>
        <h3 className="font-semibold text-2xl">New PIN:</h3>
        <p className="text-2xl font-bold">{newPin || "Not generated"}</p>
        {statusMessage.message && (
          <p className={`text-lg font-semibold ${statusMessage.color}`}>{statusMessage.message}</p>
        )}
      </div>

      <div className="flex space-x-6">
        <button
          className=" adaptavist-orange hover:adaptavist-orange-darker adaptavist-grey-text w-full py-3 px-6 rounded whitespace-nowrap text-lg"
          onClick={handleGeneratePin}>
          Generate PIN
        </button>
        <button
          className="adaptavist-orange hover:adaptavist-orange-darker adaptavist-grey-text w-full py-3 px-6 rounded whitespace-nowrap text-lg"
          onClick={handleConfirmPin}>
          Confirm PIN
        </button>
      </div>
    </div>
  );
}

export default Password;

