import React from "react";
import Login from "./Login";
import Dashboard from "./Dashboard";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import EmployeeProfile from "./EmployeeProfile";
import Password from "./Password";
import PurchaseHistory from "./PurchaseHistory";
import Profile from "./Profile";
import { useAdminStore } from "../admin-store";
import { AdminLogin } from "./admin/AdminLogin";
import { LunchSignup } from "./admin/lunch/LunchSignup";
import ChooseLocation from "./ChooseLocation";

function SnackBuddy() {
  const isAdmin = useAdminStore((state) => state.isAdmin);

  return (
    <div className="snackbuddy h-screen">
      <Router>
        <Routes>
          <Route path="/" element={<ChooseLocation />} />
          <Route path="/login/:location" element={<Login />} />
          {!isAdmin && <Route path="/admin" element={<AdminLogin />} />}
          {isAdmin && <Route path="/admin" element={<LunchSignup />} />}
          <Route path="/dashboard/:location" element={<Dashboard />} />
          <Route path="/employee-profile" element={<EmployeeProfile />}>
            <Route index element={<Profile />} />
            <Route path="password" element={<Password />} />
            <Route path="purchase-history" element={<PurchaseHistory />} />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default SnackBuddy;
