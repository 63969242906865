import React from "react";

function NumberPad({ handleNumberClick, handleDeleteClick }) {
  const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9];

  return (
    <div className="number-pad grid grid-cols-3 gap-2">
      {numbers.map((number) => (
        <button
          key={number}
          className="number-button bg-white text-gray-700 rounded-full w-16 h-16 flex items-center justify-center text-2xl font-semibold focus:outline-none hover:bg-opacity-50"
          onClick={() => handleNumberClick(number)}>
          {number}
        </button>
      ))}
      <button aria-hidden="true" tabIndex="-1"></button>
      <button
        className="number-button bg-white text-gray-700 rounded-full w-16 h-16 flex items-center justify-center text-2xl font-semibold focus:outline-none hover:bg-opacity-50"
        onClick={() => handleNumberClick(0)}>
        0
      </button>
      <button
        className="number-button bg-white text-gray-700 rounded-full w-16 h-16 flex items-center justify-center text-2xl font-semibold focus:outline-none hover:bg-opacity-50"
        onClick={handleDeleteClick}>
        DEL
      </button>
    </div>
  );
}

export default NumberPad;
