import React from "react";

const AppleFull = () => {
  return (
    <svg
      viewBox="0 0 512 512"
      baseProfile="tiny"
      overflow="visible"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      fill="#000000">
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
      <g id="SVGRepo_iconCarrier">
        {" "}
        <g id="Layer_1"></g> <g id="Layer_2"></g>{" "}
        <g id="Layer_3">
          {" "}
          <g>
            {" "}
            <path
              d="M237.284,146.893c-16.93-2.801-87.407-19.981-64.942-109.933c0.6-2.402,2.89-3.997,5.349-3.708 c21.193,2.492,112.227,18.727,64.802,111.014C241.509,146.18,239.406,147.244,237.284,146.893z"
              fill="#59C230"></path>{" "}
            <path
              d="M177.69,33.252c-2.03-0.239-3.929,0.818-4.873,2.542c32.01,7.14,89.329,30.903,50.378,107.659 c5.922,1.897,10.935,2.919,14.088,3.441c2.123,0.351,4.225-0.713,5.208-2.627C289.917,51.979,198.883,35.743,177.69,33.252z"
              fill="#4EB229"></path>{" "}
            <path
              d="M256,133.463c0,0-108.064-49.589-177.236,31.584C50.341,198.402,38.546,242.7,45.27,285.919 c8.452,54.331,31.573,143.73,94.49,200.652c0,0,29.04,40.301,116.24,0c87.2,40.301,116.24,0,116.24,0 c62.917-56.923,86.039-146.322,94.491-200.652c6.724-43.219-5.071-87.518-33.494-120.873C364.064,83.874,256,133.463,256,133.463z "
              fill="#F54432"></path>{" "}
            <g>
              {" "}
              <path
                d="M236.299,133.463c0,0,3.587-1.643,9.851-3.838c-14.166-4.965-42.079-12.766-73.539-10.864 C208.769,120.838,236.299,133.463,236.299,133.463z"
                fill="#E83826"></path>{" "}
              <path
                d="M236.299,486.572c-23.691,10.949-43.081,15.941-58.77,17.416c16.369,1.725,38.789-0.76,68.62-13.107 C242.948,489.556,239.672,488.131,236.299,486.572z"
                fill="#E83826"></path>{" "}
              <path
                d="M433.236,165.047c-34.262-40.206-78.06-48.325-113.547-46.286c31.122,1.881,65.703,13.26,93.846,46.286 c28.423,33.355,40.218,77.653,33.494,120.873c-8.452,54.33-31.573,143.73-94.49,200.652c0,0-10.49,14.541-37.769,17.416 c42.057,3.953,57.47-17.416,57.47-17.416c62.917-56.923,86.039-146.322,94.49-200.652 C473.453,242.7,461.659,198.402,433.236,165.047z"
                fill="#E83826"></path>{" "}
            </g>{" "}
          </g>{" "}
        </g>{" "}
        <g id="Layer_4"></g> <g id="Layer_5"></g> <g id="Layer_6"></g> <g id="Layer_7"></g>{" "}
        <g id="Layer_8"></g> <g id="Layer_9"></g> <g id="Layer_10"></g> <g id="Layer_11"></g>{" "}
        <g id="Layer_12"></g> <g id="Layer_13"></g> <g id="Layer_14"></g> <g id="Layer_15"></g>{" "}
        <g id="Layer_16"></g> <g id="Layer_17"></g> <g id="Layer_18"></g> <g id="Layer_19"></g>{" "}
        <g id="Layer_20"></g> <g id="Layer_21"></g> <g id="Layer_22"></g> <g id="Layer_23"></g>{" "}
        <g id="Layer_24"></g> <g id="Layer_25"></g> <g id="Layer_26"></g> <g id="Layer_27"></g>{" "}
        <g id="Layer_28"></g> <g id="Layer_29"></g> <g id="Layer_30"></g> <g id="Layer_31"></g>{" "}
        <g id="Layer_32"></g> <g id="Layer_33"></g> <g id="Layer_34"></g> <g id="Layer_35"></g>{" "}
        <g id="Layer_36"></g> <g id="Layer_37"></g> <g id="Layer_38"></g> <g id="Layer_39"></g>{" "}
        <g id="Layer_40"></g> <g id="Layer_41"></g> <g id="Layer_42"></g> <g id="Layer_43"></g>{" "}
        <g id="Layer_44"></g> <g id="Layer_45"></g> <g id="Layer_46"></g> <g id="Layer_47"></g>{" "}
        <g id="Layer_48"></g> <g id="Layer_49"></g>{" "}
        <g id="Layer_50">
          {" "}
          <g>
            {" "}
            <g>
              {" "}
              <path d="M248.643,132.42c-0.923,0-1.861-0.172-2.771-0.533c-3.848-1.532-5.726-5.893-4.195-9.742 c8.86-22.259,9.053-40.759,0.573-54.987c-14.46-24.262-50.557-31.412-64.424-33.295c-7.408,31.385-3.68,56.268,11.093,73.982 c2.653,3.181,2.224,7.911-0.956,10.563c-3.18,2.653-7.909,2.224-10.562-0.956c-18.344-21.997-22.922-52.064-13.606-89.365 c1.522-6.085,7.359-10.125,13.573-9.401c17.288,2.032,59.457,10.074,77.766,40.792c10.993,18.444,11.154,41.395,0.479,68.213 C254.443,130.631,251.624,132.42,248.643,132.42z"></path>{" "}
            </g>{" "}
            <g>
              {" "}
              <path d="M326.027,512.016c-17.939,0.001-40.974-4.464-70.032-17.475c-29.052,13.01-52.095,17.475-70.032,17.475 c-34.684,0.002-50.297-16.688-53.47-20.586c-28.921-26.317-52.514-61.685-70.133-105.138 c-12.636-31.163-21.951-65.656-27.686-102.518c-7.258-46.657,5.755-93.571,35.702-128.714 c30.303-35.559,72.648-52.681,122.467-49.55c30.478,1.924,54.758,10.782,63.152,14.202c8.394-3.421,32.674-12.278,63.152-14.202 c49.82-3.136,92.165,13.991,122.467,49.55c29.947,35.143,42.96,82.057,35.702,128.714 c-5.734,36.863-15.049,71.355-27.686,102.518c-17.619,43.453-41.213,78.822-70.134,105.139 C376.324,495.328,360.703,512.015,326.027,512.016z M255.995,478.802c1.074,0,2.148,0.23,3.146,0.692 c42.804,19.782,70.277,19.215,85.785,15.255c16.242-4.146,22.761-12.548,23.031-12.903c0.309-0.428,0.609-0.751,1.001-1.105 c63.326-57.293,85.786-149.443,93.538-199.274c6.586-42.336-5.186-84.864-32.298-116.68 c-27.504-32.275-64.549-47.181-110.106-44.309c-34.413,2.172-60.707,14.08-60.969,14.2c-1.983,0.912-4.265,0.911-6.249,0.003l0,0 c-0.266-0.121-26.961-12.15-61.447-14.232c-45.342-2.73-82.23,12.179-109.634,44.338 c-27.112,31.815-38.884,74.344-32.298,116.681c7.752,49.831,30.213,141.981,93.538,199.273c0.392,0.354,0.745,0.749,1.053,1.177 c0.217,0.284,6.737,8.686,22.978,12.832c15.508,3.961,42.98,4.527,85.785-15.255C253.846,479.033,254.92,478.802,255.995,478.802 z"></path>{" "}
            </g>{" "}
            <g>
              {" "}
              <path d="M255.994,135.361c-4.118,0-7.47-3.321-7.499-7.446c-0.008-1.122-0.097-27.817,8.104-56.764 c11.269-39.776,32.887-64.311,62.519-70.952c4.039-0.905,8.052,1.636,8.958,5.677s-1.636,8.052-5.678,8.958 c-58.802,13.18-58.91,111.985-58.904,112.981c0.024,4.14-3.312,7.519-7.452,7.545 C256.026,135.361,256.01,135.361,255.994,135.361z"></path>{" "}
            </g>{" "}
          </g>{" "}
        </g>{" "}
      </g>
    </svg>
  );
};

export default AppleFull;
