import { useState, useEffect } from "react";
import { handleLogin } from "../api/auth";
import { useShakeAnimation } from "../hooks/useShakeAnimation";
import { useStore } from "../store";
import { useNavigate, useParams } from "react-router-dom";

export function useLoginController() {
  const setEmployee = useStore((state) => state.setEmployee);
  const [pin, setPin] = useState("");
  const [loginMessage, setLoginMessage] = useState("");
  const [shake, setShake] = useShakeAnimation();
  const navigate = useNavigate();

  const { location } = useParams();

  useEffect(() => {
    if (pin.length === 4) {
      login();
    }
  }, [pin]);

  const handleNumberClick = (number) => {
    if (pin.length < 4) {
      setPin((prevPin) => prevPin + number);
    }
  };

  const handleDeleteClick = () => {
    setPin((prevPin) => prevPin.slice(0, -1));
  };

  const handleGuestLogin = () => {
    if (location === "wiesbaden") {
      setPin(process.env.REACT_APP_GUEST_LOGIN_PIN);
    }
    if (location === "cologne") {
      setPin(process.env.REACT_APP_COLOGNE_GUEST_LOGIN_PIN);
    }
  };

  const login = async () => {
    const data = await handleLogin(pin);
    if (data.success) {
      setEmployee(data.employee);
      navigate("/dashboard/" + location);
    } else {
      setShake(true);
      setLoginMessage(data.error);
    }
    setPin("");
  };

  return {
    pin,
    shake,
    loginMessage,
    handleNumberClick,
    handleDeleteClick,
    handleGuestLogin,
  };
}
