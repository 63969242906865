import React, { useState, useEffect } from "react";
import EmployeeSelector from "./EmployeeSelector";
import toast, { Toaster } from "react-hot-toast";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export const LunchSignup = () => {
  const [employees, setEmployees] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [numberOfGuests, setNumberOfGuests] = useState("0");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const successNotification = () => toast.success("Sucessfully signed up");
  const errorNotification = () => toast.error("Error signing up");

  useEffect(() => {
    (async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/employees`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (response.ok) {
          const employeeData = await response.json();

          setEmployees(
            employeeData
              .map((e) => ({ id: e["_id"], name: e["Full name"] }))
              .filter((e) => e.name !== "Guest Wiesbaden")
              .sort((a, b) => a.name.localeCompare(b.name))
          );
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsSubmitting(false);
      }
    })();
  }, []);

  const handleSelectChange = (selectedOptions) => {
    setSelectedEmployees(selectedOptions);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleNumberOfGuestChange = (e) => {
    setNumberOfGuests(e.target.value);
  };
  const handleSignup = async () => {
    setIsSubmitting(true);
    try {
      await fetch(`${process.env.REACT_APP_BACKEND_URL}/sign-up-for-lunch`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          date: selectedDate,
          employeeIds: selectedEmployees.map((e) => e.value),
          numberOfGuests: numberOfGuests,
        }),
      });
      successNotification();
      setSelectedEmployees([]);
      setNumberOfGuests(0);
    } catch (error) {
      console.log("error signing up", error);
      errorNotification();
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="flex items-center justify-center h-screen bg-gradient-to-r from-gray-100 to-veniture-lighter60">
      <div className="max-w w-full p-20">
        <div className="bg-white shadow-md rounded-md p-6">
          <h2 className="text-lg font-bold mb-2">Lunch Signup for {selectedDate.toLocaleDateString()}</h2>
          <div
            className="bg-center bg-cover mb-3"
            style={{
              backgroundImage: `url('https://images.unsplash.com/photo-1455619452474-d2be8b1e70cd?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=3540&q=80')`,
              height: "250px",
            }}
          ></div>
          <div className="h-full">
            <div className="w-full py-2">
              <label className="block text-gray-700 text-sm font-bold mb-2">Date</label>
              <DatePicker
                selected={selectedDate}
                onChange={handleDateChange}
                className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                dateFormat="dd/MM/yyyy"
              />
            </div>
            <EmployeeSelector employees={employees} selectedEmployees={selectedEmployees} onSelectChange={(selectedOptions) => handleSelectChange(selectedOptions)} />
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="number-of-guests">
                Number of guests
              </label>
              <input
                className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="number-of-guests"
                type="number"
                placeholder="Enter number of guests"
                value={numberOfGuests}
                onChange={handleNumberOfGuestChange}
              />
            </div>
            <div className="flex justify-end">
              <button
                className={`font-bold py-2 px-4 mt-10 rounded focus:outline-none focus:shadow-outline mt-4 ${
                  isSubmitting ? "bg-gray-300 text-gray-600 cursor-not-allowed" : "adaptavist-orange hover:adaptavist-orange-darker adaptavist-grey-text"
                }`}
                onClick={async () => {
                  await handleSignup();
                }}
                disabled={isSubmitting}
              >
                {isSubmitting ? "Signing up..." : "Finish Signup"}
              </button>
            </div>
          </div>
        </div>
      </div>
      <Toaster />
    </div>
  );
};

