import React from "react";
import { useNavigate, Link, Outlet, useLocation } from "react-router-dom";

function EmployeeProfile() {
  const navigate = useNavigate();
  const location = useLocation();

  const handleBackToDashboard = () => {
    navigate("/dashboard");
  };

  const isActive = (path) => location.pathname.endsWith(path);

  return (
    <div className="employee-profile min-h-screen bg-center flex justify-center items-center p-4 adaptavist-black">
      <div className="relative adaptavist-grey bg-opacity-90 shadow-xl p-8 rounded-xl min-w-[700px] min-h-[700px] max-w-full max-h-full flex flex-col justify-between">
        {" "}
        <div className="mb-6 text-center">
          <h1 className="text-3xl font-bold text-adaptavist-base">Employee Profile</h1>
          <div className="flex justify-center mt-4">
            <div className="flex border-b-2 border-gray-200">
              <Link
                to="."
                className={`py-4 px-6 ${
                  isActive("profile")
                    ? "border-b-2 border-adaptavist-base text-adaptavist-base"
                    : ""
                }`}>
                Profile
              </Link>
              <Link
                to="password"
                className={`py-4 px-6 ${
                  isActive("password")
                    ? "border-b-2 border-adaptavist-base text-adaptavist-base"
                    : ""
                }`}>
                Password
              </Link>
              <Link
                to="purchase-history"
                className={`py-4 px-6 ${
                  isActive("purchase-history")
                    ? "border-b-2 border-adaptavist-base text-adaptavist-base"
                    : ""
                }`}>
                Purchase History
              </Link>
            </div>
          </div>
        </div>
        <div className="flex-grow overflow-auto">
          <Outlet />
        </div>
        <div className="text-center p-4">
          <button
            className="back-to-dashboard-button w-full md:w-auto py-2 px-8 adaptavist-grey-text rounded adaptavist-orange  hover:adaptavist-orange-darker"
            onClick={handleBackToDashboard}>
            Back to Dashboard
          </button>
        </div>
      </div>
    </div>
  );
}

export default EmployeeProfile;

