import React from "react";
import { useNavigate } from "react-router-dom";

function ChooseLocation() {
  const navigate = useNavigate();

  return (
    <div className="login-page-container flex flex-col sm:flex-row h-screen">
      <button
        className="group relative basis-1/2 overflow-hidden transition-transform duration-300 ease-in-out hover:scale-105"
        onClick={() => {
          navigate("/login/cologne");
        }}
      >
        <img className="w-full h-full object-cover transition-opacity duration-300 group-hover:opacity-80" src="/images/cologne.jpg" alt="Cologne" />
        <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 bg-black bg-opacity-50 text-white text-2xl font-bold">
          Cologne
        </div>
      </button>
      <button
        className="group relative basis-1/2 overflow-hidden transition-transform duration-300 ease-in-out hover:scale-105"
        onClick={() => {
          navigate("/login/wiesbaden");
        }}
      >
        <img className="w-full h-full object-cover transition-opacity duration-300 group-hover:opacity-80" src="/images/wiesbaden.jpg" alt="Wiesbaden" />
        <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 bg-black bg-opacity-50 text-white text-2xl font-bold">
          Wiesbaden
        </div>
      </button>
    </div>
  );
}

export default ChooseLocation;
