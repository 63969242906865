import { create } from "zustand";

const useStore = create((set, get) => ({
  employee: JSON.parse(localStorage.getItem("employee")) || null,
  setEmployee: (employee) => {
    localStorage.setItem("employee", JSON.stringify(employee));
    set({ employee });
  },
  cartItems: [],
  setCartItems: (items) => set({ cartItems: items }),
  handleLogout: () => {
    localStorage.removeItem("employee");
    set({
      employee: null,
      cartItems: [],
      selectedCategory: [],
      snacks: [],
      selectedCategories: [],
      favoriteSnackIds: [],
      employeePurchasesEvaluation: [],
      monthlyExpenses: 0,
      purchases: null,
    });
  },
  selectedCategory: [],
  setSelectedCategory: (category) => set({ selectedCategory: category }),
  snacks: [],
  setSnacks: (snacks) => set({ snacks }),
  resetSnacks: () => set({ snacks: [] }),
  selectedCategories: [],
  setSelectedCategories: (categories) => set({ selectedCategories: categories }),
  favoriteSnackIds: [],
  setFavoriteSnackIds: (ids) => set({ favoriteSnackIds: ids }),
  employeePurchasesEvaluation: [],
  setEmployeePurchasesEvaluation: (evaluation) => set({ employeePurchasesEvaluation: evaluation }),
  monthlyExpenses: 0,
  setMonthlyExpenses: (expenses) => set({ monthlyExpenses: expenses }),
  purchases: null,
  setPurchases: (purchases) => set({ purchases }),
}));

export { useStore };
