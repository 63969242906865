import { useStore } from "../store";

export function useSnackListController() {
  const cartItems = useStore((state) => state.cartItems);
  const setCartItems = useStore((state) => state.setCartItems);

  const addToCart = (snack) => {
    const existingItem = cartItems.find((item) => item.id === snack._id);
    if (existingItem) {
      setCartItems(
        cartItems.map((item) => {
          if (item.id === snack._id) {
            return { ...item, quantity: item.quantity + 1 };
          } else {
            return item;
          }
        })
      );
    } else {
      setCartItems([
        ...cartItems,
        { id: snack._id, name: snack.Name, price: snack.Price, quantity: 1 },
      ]);
    }
  };

  return {
    addToCart,
  };
}
