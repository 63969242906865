import React from "react";
import { useStore } from "../store";
import { useEmployeeGreetingController } from "../controllers/EmployeeGreetingController";
import { useNavigate } from "react-router-dom";

function EmployeeGreeting() {
  const employee = useStore((state) => state.employee);
  const monthlyExpenses = useStore((state) => state.monthlyExpenses);
  const navigate = useNavigate();

  useEmployeeGreetingController();

  const handleProfileClick = () => {
    navigate("/employee-profile");
  };

  return (
    <div className="employee-information m-1 md:m-5 flex items-center justify-between mb-20">
      <div className="adaptavist-grey-text text-sm sm:text-sm md:text-md lg:text-lg xl:text-xl flex flex-col">
        <div>Hi, {employee["First name"]}!</div>
        <div>
          Month's Expenses: <span className="font-bold">{monthlyExpenses.toFixed(2)}€</span>
        </div>
      </div>
      <div className="profile-image-container">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          className="adaptavist-orange-text hover:adaptavist-orange-darker-color w-8 h-8 sm:w-10 sm:h-10 md:w-12 md:h-12 lg:w-14 lg:h-14 xl:w-16 xl:h-16 cursor-pointer ml-4"
          onClick={handleProfileClick}>
          <path
            fillRule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-5.5-2.5a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0zM10 12a5.99 5.99 0 00-4.793 2.39A6.483 6.483 0 0010 16.5a6.483 6.483 0 004.793-2.11A5.99 5.99 0 0010 12z"
            clipRule="evenodd"
          />
        </svg>
      </div>
    </div>
  );
}

export default EmployeeGreeting;

