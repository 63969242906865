import React from "react";
import { useStore } from "../store";

function PurchaseHistory() {
  const employeePurchasesEvaluation = useStore((state) => state.employeePurchasesEvaluation);

  let currentMonthPurchases = [];
  if (employeePurchasesEvaluation && employeePurchasesEvaluation.length > 0) {
    currentMonthPurchases = employeePurchasesEvaluation[0].purchases.map((purchase) => ({
      date: new Date(purchase.date).toLocaleDateString("de-DE"),
      snackName: purchase.snackName,
      price: purchase.price.toFixed(2),
    }));
  }

  let previousPurchases = [];
  if (employeePurchasesEvaluation && employeePurchasesEvaluation.length > 1) {
    previousPurchases = employeePurchasesEvaluation.slice(1).map((month) => ({
      date: new Date(month.year, month.month - 1).toLocaleDateString("de-DE", {
        month: "long",
        year: "numeric",
      }),
      total: month.total.toFixed(2),
    }));
  }

  return (
    <div className="flex justify-between max-h-[300px]">
      <div className="w-1/2 mr-2">
        <h2 className="sticky top-0 mb-2 text-lg font-bold">Current month</h2>
        <div className="overflow-auto max-h-[350px]">
          {currentMonthPurchases.map((purchase, index) => (
            <div key={index} className="flex justify-between items-center px-2">
              <p>{purchase.date}</p>
              <p className="max-w-[150px] overflow-hidden overflow-ellipsis whitespace-nowrap">
                {purchase.snackName}
              </p>
              <p>{purchase.price} €</p>
            </div>
          ))}
        </div>
      </div>

      <div className="w-1/2 ml-2">
        <h2 className="sticky top-0 mb-2 text-lg font-bold">Last months</h2>
        <div className="overflow-auto max-h-[350px]">
          {previousPurchases.map((purchase, index) => (
            <div key={index} className="flex justify-between items-center px-2">
              <p>{purchase.date}</p>
              <p>{purchase.total} €</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default PurchaseHistory;
