import React from "react";

function PinInput({ pin, shake }) {
  const pinElements = Array.from({ length: 4 }, (_, index) => (
    <div
      key={index}
      className={`w-3 h-3 sm:w-4 sm:h-4 border-2 border-adaptavist-orange rounded-full ${
        index < pin.length ? "adaptavist-orange border-adaptavist-orange" : ""
      }`}></div>
  ));

  return <div className={`flex space-x-2 ${shake ? "animate-shake" : ""}`}>{pinElements}</div>;
}

export default PinInput;

