import React, { useState } from "react";
import { useSnackListController } from "../controllers/SnackController";
import StarFull from "../icons/StarFull";
import StarEmpty from "../icons/StarEmpty";
import axios from "axios";
import { useStore } from "../store";

function Snack({ snack }) {
  const { Name: name, Price: price, Photo: photo } = snack;
  const { addToCart } = useSnackListController();
  const employee = useStore((state) => state.employee);

  const setEmployee = useStore((state) => state.setEmployee);

  const isSnackFavorite = employee["Favorite Snacks"]?.includes(snack._id) || false;
  const [isFavorite, setIsFavorite] = useState(isSnackFavorite);

  const handleFavoriteClick = async (e) => {
    e.stopPropagation();
    const employeeId = employee._id;
    const snackId = snack._id;

    let updatedEmployee = { ...employee };

    if (isFavorite) {
      const success = await removeFavoriteSnack(employeeId, snackId);
      if (success) {
        updatedEmployee["Favorite Snacks"] = updatedEmployee["Favorite Snacks"].filter(
          (id) => id !== snackId
        );
        setEmployee(updatedEmployee);
      }
      setIsFavorite(!success);
    } else {
      const success = await addFavoriteSnack(employeeId, snackId);
      if (success) {
        if (updatedEmployee["Favorite Snacks"]) {
          updatedEmployee["Favorite Snacks"].push(snackId);
        } else {
          updatedEmployee["Favorite Snacks"] = [snackId];
        }
        setEmployee(updatedEmployee);
      }
      setIsFavorite(success);
    }
  };

  const addFavoriteSnack = async (employeeId, snackId) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/add-favorite-snack`, {
        employeeId,
        snackId,
      });

      if (response.status === 200) {
        return true;
      } else {
        console.error("Failed to add favorite snack.");
        return false;
      }
    } catch (error) {
      console.error("Error:", error);
      return false;
    }
  };

  const removeFavoriteSnack = async (employeeId, snackId) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/remove-favorite-snack`,
        {
          data: { employeeId, snackId },
        }
      );

      if (response.status === 200) {
        return true;
      } else {
        console.error("Failed to remove favorite snack.");
        return false;
      }
    } catch (error) {
      console.error("Error:", error);
      return false;
    }
  };

  const handleClick = () => {
    addToCart(snack);
  };

  return (
    <div
      className="group flex flex-col snack drop-shadow rounded-lg p-4 ring-1 ring-slate-900/5 shadow-lg space-y-0.5 h-64 custom-hover adaptavist-grey"
      key={snack._id}
      onClick={handleClick}>
      <button onClick={handleFavoriteClick} className="top-0 right-0 p-1 h-8 w-8">
        {isFavorite ? (
          <StarFull className="w-full h-full" />
        ) : (
          <StarEmpty className="w-full h-full" />
        )}
      </button>

      <div className="m-4 h-4/6">
        <div className="image-container h-28 w-full">
          <img
            className="snack-photo h-full w-full object-contain object-center"
            src={photo}
            alt={name}
          />
        </div>
      </div>
      <div className="flex flex-col h-1/3 justify-between">
        <div className="flex">
          <p className="text-xs md:text-sm lg:text-xs xl:text-sm 2xl:text-sm text-clip overflow-hidden inline-block align-baseline snack-name font-bold line-clamp-3 adaptavist-black-text">
            {name}
          </p>
        </div>
        <div className="flex">
          <span className="text-xs md:text-sm lg:text-xs xl:text-sm 2xl:text-sm inline-block align-baseline snack-price adaptavist-black-text font-bold">
            {Number(price).toFixed(2)} €
          </span>
        </div>
      </div>
    </div>
  );
}

export default Snack;

