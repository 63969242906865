import React, { useEffect } from "react";
import { useStore } from "../store";
import SnackList from "./SnackList";
import Cart from "./Cart";
import EmployeeGreeting from "./EmployeeGreeting";
import { useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";

function Dashboard() {
  const employee = useStore((state) => state.employee);
  const navigate = useNavigate();
  const setSelectedCategory = useStore((state) => state.setSelectedCategory);

  const handleCategorySelected = (category) => {
    setSelectedCategory(category);
  };

  useEffect(() => {
    if (!employee) {
      navigate("/");
    }
  }, [employee, navigate]);

  return (
    <div className="dashboard flex flex-row h-full w-full bg-gradient-to-r adaptavist-black">
      <div className="max-w-[70%] w-[70%] h-full flex-col ml-2">
        <div className="flex items-center m-1 md:m-5 h-14 flex items-center justify-start text-xl sm:text-3xl md:text-5xl lg:text-7xl xl:text-8xl">
          <span className="font-bold text-adaptavist-base">SNACK</span>
          <span className="font-bold adaptavist-grey-text">BUDDY</span>
          <div className="adaptavist-logo">
            <img className="w-20 sm:w-20 md:w-24 lg:w-52 xl: 2xl: " src="/images/adaptavist.png" alt="Adaptavist" />
          </div>
        </div>
        <Sidebar onCategorySelected={handleCategorySelected} />
        <div className="overflow-y-auto h-full">
          <SnackList />
        </div>
      </div>
      <div className="divider-line ml-1 mr-1" />
      <div className="w-[30%] max-w-[30%] h-full flex flex-col">
        <EmployeeGreeting />
        <Cart />
      </div>
    </div>
  );
}

export default Dashboard;
