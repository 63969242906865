import React from "react";

const ChocolateFull = () => {
  return (
    <svg
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      role="img"
      className="iconify iconify--emojione"
      preserveAspectRatio="xMidYMid meet"
      fill="#000000"
      transform="rotate(45)">
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
      <g id="SVGRepo_iconCarrier">
        {" "}
        <path fill="#b37b47" d="M15 4h8v8h-8z">
          {" "}
        </path>{" "}
        <path fill="#a16745" d="M15 4l-2-2v12l2-2z">
          {" "}
        </path>{" "}
        <path fill="#633f2c" d="M23 12l2 2V2l-2 2z">
          {" "}
        </path>{" "}
        <path fill="#8c5b3e" d="M23 4l2-2H13l2 2z">
          {" "}
        </path>{" "}
        <path fill="#523420" d="M23 12h-8l-2 2h12z">
          {" "}
        </path>{" "}
        <path fill="#b37b47" d="M27 4h8v8h-8z">
          {" "}
        </path>{" "}
        <path fill="#a16745" d="M27 12V4l-2-2v12z">
          {" "}
        </path>{" "}
        <path d="M37 14V6.5c-1.2-1-2-2.5-2-2.5v8l2 2" fill="#633f2c">
          {" "}
        </path>{" "}
        <path d="M35 4s-.1-1.2.3-2H25l2 2h8" fill="#8c5b3e">
          {" "}
        </path>{" "}
        <path fill="#523420" d="M35 12h-8l-2 2h12z">
          {" "}
        </path>{" "}
        <path d="M39 7.3V12h1.3C39 10 39 7.3 39 7.3z" fill="#b37b47">
          {" "}
        </path>{" "}
        <path d="M39 7.3c-.6-.2-1.5-.4-2-.8V14l2-2V7.3" fill="#a16745">
          {" "}
        </path>{" "}
        <path d="M37 14h7.2s-2.7-.6-4-2H39l-2 2" fill="#523420">
          {" "}
        </path>{" "}
        <path fill="#b37b47" d="M15 16h8v8h-8z">
          {" "}
        </path>{" "}
        <path fill="#a16745" d="M15 16l-2-2v12l2-2z">
          {" "}
        </path>{" "}
        <path fill="#633f2c" d="M23 16v8l2 2V14z">
          {" "}
        </path>{" "}
        <path fill="#8c5b3e" d="M23 16l2-2H13l2 2z">
          {" "}
        </path>{" "}
        <path fill="#523420" d="M23 24h-8l-2 2h12z">
          {" "}
        </path>{" "}
        <path fill="#b37b47" d="M27 16h8v8h-8z">
          {" "}
        </path>{" "}
        <path fill="#a16745" d="M27 16l-2-2v12l2-2z">
          {" "}
        </path>{" "}
        <path fill="#633f2c" d="M35 16v8l2 2V14z">
          {" "}
        </path>{" "}
        <path fill="#8c5b3e" d="M35 16l2-2H25l2 2z">
          {" "}
        </path>{" "}
        <path fill="#523420" d="M27 24l-2 2h12l-2-2z">
          {" "}
        </path>{" "}
        <path d="M44.8 16H39v8h8v-6.9c-.9-.3-1.7-.3-2.2-1.1" fill="#b37b47">
          {" "}
        </path>{" "}
        <path fill="#a16745" d="M37 14v12l2-2v-8z">
          {" "}
        </path>{" "}
        <path d="M47 17.1V24l2 2v-8.7c-.6 0-1.5 0-2-.2" fill="#633f2c">
          {" "}
        </path>{" "}
        <path d="M39 16h5.8c-.4-.5-.5-1.2-.6-2H37l2 2" fill="#8c5b3e">
          {" "}
        </path>{" "}
        <path fill="#523420" d="M39 24l-2 2h12l-2-2z">
          {" "}
        </path>{" "}
        <path fill="#b37b47" d="M15 28h8v8h-8z">
          {" "}
        </path>{" "}
        <path fill="#a16745" d="M15 28l-2-2v11.4l2-1.4z">
          {" "}
        </path>{" "}
        <path fill="#633f2c" d="M23 28v8h2V26z">
          {" "}
        </path>{" "}
        <g fill="#8c5b3e">
          {" "}
          <path d="M23 28l2-2H13l2 2z"> </path> <path d="M23 36h-8l-2 1.4z"> </path>{" "}
        </g>{" "}
        <path fill="#b37b47" d="M27 28h8v8h-8z">
          {" "}
        </path>{" "}
        <path fill="#a16745" d="M27 28l-2-2v10h2z">
          {" "}
        </path>{" "}
        <path fill="#633f2c" d="M35 36h2V26l-2 2z">
          {" "}
        </path>{" "}
        <path fill="#8c5b3e" d="M27 28h8l2-2H25z">
          {" "}
        </path>{" "}
        <path fill="#b37b47" d="M39 28h8v8h-8z">
          {" "}
        </path>{" "}
        <path fill="#a16745" d="M39 36v-8l-2-2v10z">
          {" "}
        </path>{" "}
        <path fill="#633f2c" d="M47 36h2V26l-2 2z">
          {" "}
        </path>{" "}
        <path fill="#8c5b3e" d="M39 28h8l2-2H37z">
          {" "}
        </path>{" "}
        <path fill="#23A5F7" d="M13 36.8h36V60H13z">
          {" "}
        </path>{" "}
        <path
          d="M45.4 34.9L13 36.8c21.6 0 15.6 20.3 31.7 20.3c-4.6-5.9.7-22.2.7-22.2"
          fill="#0788d9">
          {" "}
        </path>{" "}
        <g fill="#FF6633">
          {" "}
          <path d="M49 32.4c-7.1-5.6-36 4.4-36 4.4c24.9-1.9 17.6 18.9 34.9 17c-4.2-9.8 7.6-16.4 1.1-21.4">
            {" "}
          </path>{" "}
          <path d="M13 60h36v2H13z"> </path>{" "}
        </g>{" "}
      </g>
    </svg>
  );
};

export default ChocolateFull;
