// import logo from './logo.svg';
import './styles/app.css';
import React from 'react';
import SnackBuddy from './components/SnackBuddy';

function App() {
  return (
    <div className="App font-body overflow-hidden">
      <SnackBuddy />
    </div>
  );
}

export default App;
