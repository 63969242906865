import React, { useState } from "react";
import AppleFull from "../icons/AppleFull";
import AppleEmpty from "../icons/AppleEmpty";
import ChocolateFull from "../icons/ChocolateFull";
import ChocolateEmpty from "../icons/ChocolateEmpty";
import StarEmpty from "../icons/StarEmpty";
import StarFull from "../icons/StarFull";

function Sidebar({ onCategorySelected }) {
  const [selectedButtons, setSelectedButtons] = useState([]);

  const handleButtonClick = (category) => {
    if (selectedButtons.includes(category)) {
      setSelectedButtons([]);
      onCategorySelected([]);
    } else {
      setSelectedButtons([category]);
      if (category === "Favorites") {
        onCategorySelected("Favorite Snacks");
      } else {
        onCategorySelected([category]);
      }
    }
  };

  const isSelected = (category) => {
    return selectedButtons.includes(category);
  };

  return (
    <div className="sidebar w-full sticky top-0 mt-4 mb-4 flex flex-row items-left justify-start from-gray-100 to-gray-50 ml-4">
      <button onClick={() => handleButtonClick("Favorites")} className={`w-16 h-16 p-1.5`}>
        {isSelected("Favorites") ? (
          <StarFull className="max-w-full max-h-full" />
        ) : (
          <StarEmpty className="max-w-full max-h-full" />
        )}
      </button>
      <button onClick={() => handleButtonClick("Fruit")} className={`w-16 h-16 p-1.5`}>
        {isSelected("Fruit") ? (
          <AppleFull className="max-w-full max-h-full" />
        ) : (
          <AppleEmpty className="max-w-full max-h-full" />
        )}
      </button>
      <button onClick={() => handleButtonClick("Sweets")} className={`w-16 h-16 p-1.5`}>
        {isSelected("Sweets") ? (
          <ChocolateFull className="max-w-full max-h-full" />
        ) : (
          <ChocolateEmpty className="max-w-full max-h-full" />
        )}
      </button>
    </div>
  );
}

export default Sidebar;

